import React from 'react';

import config from '../../config';

export default function Footer() {
  return (
    <header id="header">
      <h1>{config.heading}</h1>
      <p>
      We are building something that focuses on helping people grow though self-care and self-compassion.
      </p>
    </header>
  );
}
