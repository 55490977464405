module.exports = {
  siteTitle: 'Diets are Bullshit', // <title>
  manifestName: 'Diets are Bullshit',
  manifestShortName: 'DAB', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'Diets are BS',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-eventually/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Diets Are Bullshit',
  subHeading:
    "Building something that focuses on helping people grow though self-care and self-compassion. Stop dieting, start living.",
  subText: "Sign up to follow the ride, from idea to launch.",  
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://bobbybrooksatx.com',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/bobbybrooksatx',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/bobbybrooksatx',
    },
  ],
};
